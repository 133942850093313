<script>
const sidebarMenu = [
    { path: '/dashboard/v2', title: 'Dashboard', showForRoles: [1] },
    //{ path: "/Modulos",icon: 'fa fa-archway', title: "MÓDULOS DEL SISTEMA" },
    { path: "/Dashboard", icon: "fa fa-th", title: "Dashboard Oficial", showForRoles: [1, 2, 3, 4, 5, 6, 7, 8] },
    { path: "/Profile", icon: "fa fa-user", title: "Mi Perfil", showForRoles: [1, 2, 3, 4, 5, 6, 7, 8] },
    { path: "/Persona",icon: 'fa fa-users', title: "Filiación", showForRoles: [1, 2, 3, 4, 7, 8] },
   
  { icon: 'fa fa-medkit', title: 'Paramétricas', showForRoles: [1, 2, 3, 4, 7],
    children: [
      { path: '/Persona', title: 'Persona', showForRoles: [1,7] },
      { path: '/Rol', title: 'Rol', showForRoles: [1] },
      { path: '/Departamento', title: 'Departamento', showForRoles: [1,7] },
      { path: '/Fuerza', title: 'Fuerza', showForRoles: [1,7] },
      { path: '/Especialidad', title: 'Especialidad', showForRoles: [1,7] },
      { path: '/Sexo', title: 'Sexo', showForRoles: [1,7] },
      { path: '/GrupoSanguineo', title: 'Grupo Sanguineo', showForRoles: [1,7] },
      { path: '/EstadoCivil', title: 'Estado Civil', showForRoles: [1,7] },
      { path: '/Escalafon', title: 'Escalafon', showForRoles: [1,7] },
      { path: '/Profesion', title: 'Profesion', showForRoles: [1, 2, 3, 4, 7] },
      { path: '/Cargo', title: 'Cargo', showForRoles: [1, 2, 3, 4, 7] },
      { path: '/Grado', title: 'Grado', showForRoles: [1,7] },
      { path: '/Reparticion', title: 'Reparticion', showForRoles: [1, 2, 3, 4, 7] },
      { path: '/TipoArmamento', title: 'Tipo Armamento', showForRoles: [1,7] },
      { path: '/ArmamentoDotacion', title: 'Armamento Dotacion', showForRoles: [1,7] },
      { path: '/TipoFamiliar', title: 'TipoFamiliar', showForRoles: [1,7] },
      { path: '/TipoCapacitacion', title: 'Tipo Capacitacion', showForRoles: [1,7] },
      { path: '/CapacitacionProfesional', title: 'Capacitacion Profesional', showForRoles: [1,7] },
      { path: '/Arma', title: 'Arma', showForRoles: [1,7] },
      { path: '/TipoMerito', title: 'Tipo Mérito', showForRoles: [1,7] },
      { path: '/Merito', title: 'Mérito', showForRoles: [1,7] },
      { path: '/EstadoDocumento', title: 'Estado Documento', showForRoles: [1,7] }
    ]
  },

  // { path: '/', icon: 'fa fa-medkit', title: 'Login'},
    {
        icon: "fa fa-book",
        title: "Kardex Militar",
        showForRoles: [1, 8],
        children: [
            // { path: "/Ascenso", title: "Ascenso", showForRoles: [1, 8] },
            { path: '/Familiar', title: 'Carga Familiar', showForRoles: [1, 8] },
            { path: "/Destino", title: "Cargos y Destino", showForRoles: [1, 8] },
            { path: '/OrdenDeMerito', title: 'Meritos', showForRoles: [1, 8] },
            { path: "/Demerito", title: "Demerito", showForRoles: [1, 8] },

            // { path: '/FojaConcepto', title: 'FojaConcepto', showForRoles: [1, 8] },
        ]
    },
    {
        icon: "fa fa-archway",
        title: "Servicios Personales",
        showForRoles: [1, 2, 3, 4],
        children: [
            {title: "ADMINISTRAR", showForRoles: [1,7],
              children:
               [
                { path: "/ConceptoSP", title: "Conceptos", showForRoles: [1] },
                { path: "/SituacionConcepto", title: "Situacion Conceptos", showForRoles: [1] },]
            },
            { path: "/ServicioPersonal", title: "GENERAL", showForRoles: [1, 2, 3, 4] },
            { path: "/MenuSPPermanentes?grupo=1", title: "PERMANENTES", showForRoles: [1, 3] },
            { path: "/MenuSPEventual?grupo=2", title: "EVENTUAL", showForRoles: [1, 3] },
            { path: "/MenuSPAltas?grupo=3", title: "ALTAS", showForRoles: [1, 3] },
            { path: "/MenuSPAsignacionesFamiliares?grupo=4", title: "ASIGNACIONES FAMILIARES", showForRoles: [1, 2] },
            { path: "/MenuSPLetras?grupo=5", title: "LETRAS", showForRoles: [1, 3] },
            { path: "/MenuSPConclusiones?grupo=6", title: "CONCLUSIONES", showForRoles: [1, 3] },
            { path: "/MenuSPBajas?grupo=7", title: "BAJAS", showForRoles: [1, 3] },
            { path: "/MenuSPAscensos?grupo=8", title: "ASCENSOS", showForRoles: [1, 3] },            
            { path: "/MenuSPVarios?grupo=9", title: "VARIOS", showForRoles: [1, 3] },
            { path: "/MenuSPDestino?grupo=10", title: "DESTINO", showForRoles: [1, 3, 4] },
            { path: "/MenuReporteSP", title: "REPORTES SP", showForRoles: [1, 2, 3, 4] },
            
        ]
    },
    {
        icon: "fa fa-archway",
        title: "Víaticos",
        showForRoles: [1, 7],
        children: [
          {title: "Paramétricas", showForRoles: [1,7],
              children: [
                { path: "/Lugar", title: "Lugar", showForRoles: [1,7] },
                { path: "/TipoViatico", title: "Tipo Víatico", showForRoles: [1,7] },
                { path: "/EstadoViatico", title: "Estado Víatico", showForRoles: [1,7] },
                { path: "/FleteAlmacenamiento", title: "Flete y Alacenamiento", showForRoles: [1,7] },
                { path: "/TipoPasaje", title: "TipoPasaje", showForRoles: [1,7] },
                { path: "/Pasaje", title: "Pasajes", showForRoles: [1,7] },
                { path: "/CategoriaViatico", title: "Categoría Viático", showForRoles: [1,7] },]
            },
            { path: "/PersonaViatico", title: "Víaticos", showForRoles: [1,7] },
            { path: "/GenerarViatico", title: "generarviatico", showForRoles: [1,7] },
        ]
    },
    {
        icon: "fa fa-archway",
        title: "Reportes",
        showForRoles: [1],
        children: [
            { path: "/Reporte", title: "Reportes" , showForRoles: [1,7]},
        ]
    },
    // { path: '/', icon: 'fa fa-medkit', title: 'Login'},
];

export default sidebarMenu;
</script>
