<script>
import DashboardV1 from '../pages/Dashboard-v1.vue'
import DashboardV2 from '../pages/Dashboard-v2.vue'
import DashboardV3 from '../pages/Dashboard-v3.vue'
import EmailInbox from '../pages/Email-inbox.vue'
import EmailCompose from '../pages/Email-compose.vue'
import EmailDetail from '../pages/Email-detail.vue'
import Widgets from '../pages/Widgets.vue'
import UIGeneral from '../pages/UI-general.vue'
import UITypography from '../pages/UI-typography.vue'
import UITabsAccordion from '../pages/UI-tabs-accordion.vue'
import UIModalNotification from '../pages/UI-modal-notification.vue'
import UIWidgetBoxes from '../pages/UI-widget-boxes.vue'
import UIMediaObject from '../pages/UI-media-object.vue'
import UIButtons from '../pages/UI-buttons.vue'
import UIIcons from '../pages/UI-icons.vue'
import UISimpleLineIcons from '../pages/UI-simple-line-icons.vue'
import UIIonicons from '../pages/UI-ionicons.vue'
import UILanguageBarIcon from '../pages/UI-language-bar-icon.vue'
import UISocialButtons from '../pages/UI-social-buttons.vue'
import Bootstrap4 from '../pages/Bootstrap-4.vue'
import FormElements from '../pages/Form-elements.vue'
import FormPlugins from '../pages/Form-plugins.vue'
import TableBasic from '../pages/Table-basic.vue'
import TableData from '../pages/Table-data.vue'
import ChartJs from '../pages/Chart-js.vue'
import ChartD3 from '../pages/Chart-d3.vue'
import ChartApex from '../pages/Chart-apex.vue'
import Calendar from '../pages/Calendar.vue'
import Map from '../pages/Map.vue'
import Gallery from '../pages/Gallery.vue'
import PageBlank from '../pages/Page-blank.vue'
import PageWithFooter from '../pages/Page-with-footer.vue'
import PageWithoutSidebar from '../pages/Page-without-sidebar.vue'
import PageWithRightSidebar from '../pages/Page-with-right-sidebar.vue'
import PageWithMinifiedSidebar from '../pages/Page-with-minified-sidebar.vue'
import PageWithTwoSidebar from '../pages/Page-with-two-sidebar.vue'
import PageFullHeight from '../pages/Page-full-height.vue'
import PageWithWideSidebar from '../pages/Page-with-wide-sidebar.vue'
import PageWithLightSidebar from '../pages/Page-with-light-sidebar.vue'
import PageWithMegaMenu from '../pages/Page-with-mega-menu.vue'
import PageWithTopMenu from '../pages/Page-with-top-menu.vue'
import PageWithBoxedLayout from '../pages/Page-with-boxed-layout.vue'
import PageWithMixedMenu from '../pages/Page-with-mixed-menu.vue'
import PageBoxedLayoutWithMixedMenu from '../pages/Page-boxed-layout-with-mixed-menu.vue'
import PageWithTransparentSidebar from '../pages/Page-with-transparent-sidebar.vue'
import PageWithSearchSidebar from '../pages/Page-with-search-sidebar.vue'
import ExtraTimeline from '../pages/Extra-timeline.vue'
import ExtraComingSoon from '../pages/Extra-coming-soon.vue'
import ExtraSearch from '../pages/Extra-search.vue'
import ExtraInvoice from '../pages/Extra-invoice.vue'
import ExtraError from '../pages/Extra-error.vue'
import ExtraProfile from '../pages/Extra-profile.vue'
import ExtraScrumBoard from '../pages/Extra-scrum-board.vue'
import ExtraCookieAcceptanceBanner from '../pages/Extra-cookie-acceptance-banner.vue'
import UserLoginV1 from '../pages/User-login-v1.vue'
import UserLoginV2 from '../pages/User-login-v2.vue'
import UserLoginV3 from '../pages/User-login-v3.vue'
import UserRegisterV3 from '../pages/User-register-v3.vue'
import HelperCss from '../pages/Helper-css'

import Persona from '../pages/Persona/PersonaPage.vue'
import Rol from '../pages/Rol/RolPage.vue'
import Departamento from '../pages/Departamento/DepartamentoPage.vue'
import Fuerza from '../pages/Fuerza/FuerzaPage.vue'
import Especialidad from '../pages/Especialidad/EspecialidadPage.vue'
import Cargo from '../pages/Cargo/CargoPage.vue'
import Profesion from '../pages/Profesion/ProfesionPage.vue'
import Escalafon from '../pages/Escalafon/EscalafonPage.vue'
import Grado from '../pages/Grado/GradoPage.vue'
import Familiar from '../pages/Familiar/FamiliarPage.vue'
import TipoFamiliar from '../pages/TipoFamiliar/TipoFamiliarPage.vue'
import TipoCapacitacion from '../pages/TipoCapacitacion/TipoCapacitacionPage.vue'
import CapacitacionProfesional from '../pages/CapacitacionProfesional/CapacitacionProfesionalPage.vue'
import Reparticion from '../pages/Reparticion/ReparticionPage.vue'
import Profile from '../pages/Profile/ProfilePage.vue'
import Login from '../pages/Login/LoginPage.vue'
import Sexo from '../pages/Sexo/SexoPage.vue'
import GrupoSanguineo from '../pages/GrupoSanguineo/GrupoSanguineoPage.vue'
import EstadoCivil from '../pages/EstadoCivil/EstadoCivilPage.vue'
import Arma from '../pages/Arma/ArmaPage.vue'
import TipoArmamento from '../pages/TipoArmamento/TipoArmamentoPage.vue'
import ArmamentoDotacion from '../pages/ArmamentoDotacion/ArmamentoDotacionPage.vue'
import Ascenso from '../pages/Ascenso/AscensoPage.vue'
import Destino from '../pages/Destino/DestinoPage.vue'


import TipoMerito from '../pages/TipoMerito/TipoMeritoPage.vue'

import Merito from '../pages/Merito/MeritoPage.vue'

import EstadoDocumento from '../pages/EstadoDocumento/EstadoDocumentoPage.vue'
import ConceptoSP from '../pages/ConceptoSP/ConceptoSPPage.vue'
import SituacionConcepto from '../pages/SituacionConcepto/SituacionConceptoPage.vue'
import ServicioPersonal from '../pages/ServicioPersonal/ServicioPersonalPage.vue'
import Demerito from '../pages/Demerito/DemeritoPage.vue'
import OrdenDeMerito from '../pages/OrdenDeMerito/OrdenDeMeritoPage.vue'
import FojaConcepto from '../pages/FojaConcepto/FojaConceptoPage.vue'
import MenuSP from '../pages/ServicioPersonal/MenuPage.vue'
import EstadoViatico from '../pages/EstadoViatico/EstadoViaticoPage.vue'
import TipoViatico from '../pages/TipoViatico/TipoViaticoPage.vue'
import Lugar from '../pages/Lugar/LugarPage.vue'
import CategoriaViatico from '../pages/CategoriaViatico/CategoriaViaticoPage.vue'
import TipoPasaje from '../pages/TipoPasaje/TipoPasajePage.vue'
import Pasaje from '../pages/Pasaje/PasajePage.vue'
import FleteAlmacenamiento from '../pages/FleteAlmacenamiento/FleteAlmacenamientoPage.vue'
import PersonaViatico from '../pages/PersonaViatico/PersonaViaticoPage.vue'
import GenerarViatico from '../pages/GenerarViatico/GenerarViaticoPage.vue'
import Reporte from '../pages/Reporte/ReportePage.vue'
import ReporteSP from '../pages/ReporteSP/ReporteSPPage.vue'

import Externo from '../pages/Externo/ExternoPage.vue'
import CapProf from '../pages/CapProf/CapProfPage.vue'

import CarDest from '../pages/CarDest/CarDestPage.vue'
import SeccionCargosDestinos from '../pages/SeccionCargosDestinos/SeccionCargosDestinosPage.vue'
import SeccionProcesamiento from '../pages/SeccionProcesamiento/SeccionProcesamientoPage.vue'
import SeccionDiciplina from '../pages/SeccionDiciplina/SeccionDiciplinaPage.vue'

import ControlPersonal from '../pages/ControlPersonal/ControlPersonalPage.vue'
import Modulos from '../pages/Modulos/ModulosPage.vue'
import BienestarSocial from '../pages/BienestarSocial/BienestarSocialPage.vue'
import Ascensos from '../pages/Ascensos/AscensosPage.vue'
import Dashboard from '../pages/Dashboard/Dashboard.vue'

const routes = [
  { path: '/dashboard/v1', component: DashboardV1 },
  { path: '/dashboard/v2', component: DashboardV2 },
  { path: '/dashboard/v3', component: DashboardV3 },
  { path: '/email/inbox', component: EmailInbox },
  { path: '/email/compose', component: EmailCompose },
  { path: '/email/detail', component: EmailDetail },
  { path: '/widgets', component: Widgets },
  { path: '/ui/general', component: UIGeneral },
  { path: '/ui/typography', component: UITypography },
  { path: '/ui/tabs-accordions', component: UITabsAccordion },
  { path: '/ui/modal-notification', component: UIModalNotification },
  { path: '/ui/widget-boxes', component: UIWidgetBoxes },
  { path: '/ui/media-object', component: UIMediaObject },
  { path: '/ui/buttons', component: UIButtons },
  { path: '/ui/icons', component: UIIcons },
  { path: '/ui/simple-line-icons', component: UISimpleLineIcons },
  { path: '/ui/ionicons', component: UIIonicons },
  { path: '/ui/language-bar-icon', component: UILanguageBarIcon },
  { path: '/ui/social-buttons', component: UISocialButtons },
  { path: '/bootstrap-4', component: Bootstrap4 },
  { path: '/form/elements', component: FormElements },
  { path: '/form/plugins', component: FormPlugins },
  { path: '/table/basic', component: TableBasic },
  { path: '/table/data', component: TableData },
  { path: '/chart/js', component: ChartJs },
  { path: '/chart/d3', component: ChartD3 },
  { path: '/chart/apex', component: ChartApex },
  { path: '/calendar', component: Calendar },
  { path: '/map', component: Map },
  { path: '/page-option/blank', component: PageBlank },
  { path: '/page-option/with-footer', component: PageWithFooter },
  { path: '/page-option/without-sidebar', component: PageWithoutSidebar },
  { path: '/page-option/with-right-sidebar', component: PageWithRightSidebar },
  { path: '/page-option/with-minified-sidebar', component: PageWithMinifiedSidebar },
  { path: '/page-option/with-two-sidebar', component: PageWithTwoSidebar },
  { path: '/page-option/full-height', component: PageFullHeight },
  { path: '/page-option/with-wide-sidebar', component: PageWithWideSidebar },
  { path: '/page-option/with-light-sidebar', component: PageWithLightSidebar },
  { path: '/page-option/with-mega-menu', component: PageWithMegaMenu },
  { path: '/page-option/with-top-menu', component: PageWithTopMenu },
  { path: '/page-option/with-boxed-layout', component: PageWithBoxedLayout },
  { path: '/page-option/with-mixed-menu', component: PageWithMixedMenu },
  { path: '/page-option/boxed-layout-with-mixed-menu', component: PageBoxedLayoutWithMixedMenu },
  { path: '/page-option/with-transparent-sidebar', component: PageWithTransparentSidebar },
  { path: '/page-option/with-search-sidebar', component: PageWithSearchSidebar },
  { path: '/gallery', component: Gallery },
  { path: '/extra/timeline', component: ExtraTimeline },
  { path: '/extra/coming-soon', component: ExtraComingSoon },
  { path: '/extra/search', component: ExtraSearch },
  { path: '/extra/invoice', component: ExtraInvoice },
  { path: '/extra/error', component: ExtraError },
  { path: '/extra/profile', component: ExtraProfile },
  { path: '/extra/scrum-board', component: ExtraScrumBoard },
  { path: '/extra/cookie-acceptance-banner', component: ExtraCookieAcceptanceBanner },
  { path: '/user/login-v1', component: UserLoginV1 },
  { path: '/user/login-v2', component: UserLoginV2 },
  { path: '/user/login-v3', component: UserLoginV3 },
  { path: '/user/register-v3', component: UserRegisterV3 },
  { path: '/helper/css', component: HelperCss },


  // starter
  { path: '/Persona', component: Persona },
  { path: '/Rol', component: Rol },
  { path: '/Departamento', component: Departamento },
  { path: '/Ascenso', component: Ascenso },
  { path: '/Destino', component: Destino },
  { path: '/Fuerza', component: Fuerza },
  { path: '/Especialidad', component: Especialidad },
  { path: '/Cargo', component: Cargo },
  { path: '/Escalafon', component: Escalafon },
  { path: '/Grado', component: Grado },
  { path: '/Reparticion', component: Reparticion },
  { path: '/Profesion', component: Profesion },
  { path: '/Profile', component: Profile },
  { path: '/', component: Login },
  { path: '/Sexo', component: Sexo },
  { path: '/Familiar', component: Familiar },
  { path: '/TipoFamiliar', component: TipoFamiliar },
  { path: '/TipoCapacitacion', component: TipoCapacitacion },
  { path: '/CapacitacionProfesional', component: CapacitacionProfesional },
  { path: '/GrupoSanguineo', component: GrupoSanguineo },
  { path: '/EstadoCivil', component: EstadoCivil },
  { path: '/Arma', component: Arma },
  { path: '/TipoArmamento', component: TipoArmamento },
  { path: '/ArmamentoDotacion', component: ArmamentoDotacion },
  { path: '/TipoMerito', component: TipoMerito },
  { path: '/Merito', component: Merito },
  { path: '/ControlPersonal', component: ControlPersonal },
  { path: '/Modulos', component: Modulos },
  { path: '/BienestarSocial', component: BienestarSocial },
  { path: '/Ascensos', component: Ascensos },

  { path: '/EstadoDocumento', component: EstadoDocumento },
  { path: '/ConceptoSP', component: ConceptoSP },
  { path: '/SituacionConcepto', component: SituacionConcepto },
  { path: '/ServicioPersonal', component: ServicioPersonal },
  { path: '/MenuSP', component: MenuSP },
  { path: '/MenuSPPermanentes', component: MenuSP },
  { path: '/MenuSPEventual', component: MenuSP },
  { path: '/MenuSPAltas', component: MenuSP },
  { path: '/MenuSPAsignacionesFamiliares', component: MenuSP },
  { path: '/MenuSPLetras', component: MenuSP },
  { path: '/MenuSPConclusiones', component: MenuSP },
  { path: '/MenuSPBajas', component: MenuSP },
  { path: '/MenuSPAscensos', component: MenuSP },
  { path: '/MenuSPDestino', component: MenuSP },
  { path: '/MenuSPVarios', component: MenuSP },
  { path: '/MenuReporteSP', component: ReporteSP },
  { path: '/Demerito', component: Demerito },
  { path: '/OrdenDeMerito', component: OrdenDeMerito },
  { path: '/FojaConcepto', component: FojaConcepto },
  { path: '/EstadoViatico', component: EstadoViatico },
  { path: '/TipoViatico', component: TipoViatico },
  { path: '/Lugar', component: Lugar },
  { path: '/CategoriaViatico', component: CategoriaViatico },
  { path: '/Pasaje', component: Pasaje},
  { path: '/TipoPasaje', component: TipoPasaje},
  { path: '/FleteAlmacenamiento', component: FleteAlmacenamiento},
  { path: '/PersonaViatico', component: PersonaViatico},
  { path: '/GenerarViatico', component: GenerarViatico},
  { path: '/Reporte', component: Reporte},

  { path: '/Externo', component: Externo},
  { path: '/CapProf', component: CapProf},
  { path: '/CarDest', component: CarDest},

  { path: '/SeccionCargosDestinos', component: SeccionCargosDestinos},
  { path: '/SeccionProcesamiento', component: SeccionProcesamiento},
  { path: '/SeccionDiciplina', component: SeccionDiciplina},
  { path: '/Dashboard', component: Dashboard}



]

export default routes;
</script>
